<template>
<div>
  Dashboard Page
</div>
</template>

<script>
export default {
  mounted() {
  }
}
</script>